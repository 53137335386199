.category-page-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 50px;
}

.category-title {
    font-size: 28px;
    margin-bottom: 25px;
    text-align: center;
}